import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import NewsList from './news-components/news-list';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const newsPage = () => {
    return <div>
  <Helmet>
    <title>News - Muslim's Voice Network News | Latest Updates</title>
    <meta name="description" content="Discover the latest news stories from local, national, and global perspectives. Muslim's Voice Network News brings you the news that matters most." />
    <meta name="keywords" content="Muslim news, latest updates, breaking news, global news, community stories" />
    <meta name="author" content="Muslim's Voice Network News" />
</Helmet>


        <Navbar />
        <PageHeader headertitle="News"  />
        <NewsList />
        <Footer />
    </div>
}

export default newsPage

