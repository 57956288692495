import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import EventDetails from './event-components/event-details';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const EventDetailsPage = () => {
    const { eventId } = useParams(); // Get event ID from URL params
    const location = useLocation(); // Access the passed state
    const [eventData, seteventData] = useState(null); // Initialize with null
    const [error, setError] = useState(null);

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);

        const fetcheventData = async () => {
            try {
                // Set eventData based on the location state
                if (location.state) {
                    const { event, events, posts } = location.state;
                    console.log('Received state:', location.state);
                    
                    if (event) {
                        seteventData(event);
                    } else if (events) {
                        seteventData(events);
                    } else if (posts) {
                        seteventData(posts);
                    }
                }

                // Fetch the event data only if it was not passed via state and eventId is available
                if (!eventData && eventId) {
                    const response = await fetch(`https://muslimvoicenetwork.com/api/blog.php?event=${eventId}`);
                    if (!response.ok) {
                        throw new Error('Error fetching event data');
                    }
                    const data = await response.json();
                    console.log('Fetched data:', data); // Log the response data
                    seteventData(data);
                }
            } catch (error) {
                setError(error.message);
            }
        };

        fetcheventData();
    }, [location.state, eventId, eventData]); // Dependencies for useEffect

    if (error) {
        return <p>{error}</p>;
    }

    if (!eventData) {
        return <p>Loading...</p>; // Show loading indicator if data is not yet available
    }

    const publicUrl = process.env.PUBLIC_URL + '/';

    return (
        <div>
            <Helmet>
    <title>{eventData.title ? `${eventData.title} - Muslim's Voice Network News` : 'Event Details - Muslim\'s Voice Network News'}</title>
    <meta name="description" content={eventData.meta_description ? eventData.meta_description : 'Discover detailed information about this event at Muslim\'s Voice Network News.'} />
    <meta name="keywords" content={eventData.meta_tags ? eventData.meta_tags : 'event details, Muslim events, community gatherings'} />
    <meta name="author" content="Muslim's Voice Network News" />
</Helmet>

            <Navbar />
            <PageHeader headertitle="Event Details" />
            <EventDetails
                eventData={eventData}
                postData={eventData}
            />
            <Footer />
        </div>
    );
};

export default EventDetailsPage;
