import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import HomeV2 from './components/home-v2';
import CatPage from './components/cat-page';
import CatSports from './components/cat-sports';
import lifeStyle from './components/cat-life-style';
import Fashion from './components/cat-fashion';
import Author from './components/author';
import Error from './components/error';
import Blog from './components/blog';
import News from './components/news';
import BlogDetails from './components/blog-details';
import NewsDetails from './components/news-details';
import { Provider } from 'react-redux';
import { store } from './store/store';
import CatNews from './components/cat-news';
import Privacy from './components/privacy-policy';
import ContactUs from './components/contact';
import AboutUs from './components/about';
import Events from './components/event-list';
import EventDetailsPage from './components/event-details';
import InternetStatus from './components/global-components/InternetStatus';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const CanonicalTag = () => {
	const location = useLocation();
	const canonicalURL = `https://muslimvoicenetwork.com${location.pathname}`;

	return (
		<Helmet>
			{/* Robots Meta Tag */}
			<meta name="robots" content="index, follow" />
			{/* Author */}
			<meta name="author" content="Sabir Esa, MuslimVoiceNetwork.com" />
			<link rel="canonical" href={canonicalURL} />
			<meta property="og:url" content={canonicalURL} />
			<meta property="og:type" content="website" />
		</Helmet>
	);
};


class Root extends Component {
	render() {
		return (
			<Provider store={store}>
				<BrowserRouter>
					<HelmetProvider>
						<CanonicalTag />
						<ScrollToTop />
						{/* InternetStatus Component to Monitor Network Status */}
						<InternetStatus />
						<div>
							<Switch>
								<Route exact path="/" component={HomeV2} />
								{/* Redirect from /cat-blog/:catId to /cat-news/:catId */}
								<Route path="/cat-blog/:catId">
									{({ match }) => (
										<Redirect to={`/cat-news/${match.params.catId}`} />
									)}
								</Route>
								<Route path="/cat-news/:catId" component={CatNews} />
								<Route path="/blog" component={Blog} />
								<Route path="/news" component={News} />
								<Route path="/blog-details/:blogId" component={BlogDetails} />
								<Route path="/news-details/:newsId" component={NewsDetails} />
								<Route path="/privacy-policy" component={Privacy} />
								<Route path="/contact" component={ContactUs} />
								<Route path="/about" component={AboutUs} />
								<Route path="/event" component={Events} />
								<Route path="/event-detail/:eventId" component={EventDetailsPage} />
							</Switch>
						</div>
					</HelmetProvider>
				</BrowserRouter>
			</Provider>
		)
	}
}

export default Root;

ReactDOM.hydrate(<Root />, document.getElementById('nextpage'));
