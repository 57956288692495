import React from 'react';
import Sidebar from './sidebar';
import RelatedPost from './related-post';
import Loader from '../global-components/loader';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const NewsDetails = ({ newsData, publicUrl, loading }) => {
    if (loading) {
        return <Loader />;
    }

    if (!newsData) {
        return <p>No news details available.</p>;
    }

    let imageAlt = 'News Image';
    let imageFetchPath = 'https://muslimvoicenetwork.com/public/img/';
    let shareTitle = encodeURIComponent(newsData.title || 'Untitled News');
    let currentUrl = `https://muslimvoicenetwork.com/news-detailts/${newsData.id}`;

    return (
        <>
            <Helmet>
                <title>{newsData.title ? `${newsData.title}` : "News Details - Muslim's Voice Network News"}</title>
                <meta
                    name="description"
                    content={newsData.meta_description || "Read the latest news details and in-depth analyses from Muslim's Voice Network News."}
                />
                <meta
                    name="keywords"
                    content={newsData.meta_tags || "news details, breaking news, in-depth news, Muslim news"}
                />
                <meta name="author" content="Muslim's Voice Network News" />
            </Helmet>
            <div className="blog-page-area pd-bottom-80 go-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 pd-top-50">
                            <div className="blog-details-page-inner">
                                <div className="single-blog-inner m-0">
                                    <div className="single-post-wrap style-overlay">
                                        <div className="thumb">
                                            <LazyLoadImage
                                                effect="blur"
                                                src={newsData.post_image ? `${newsData.post_image}` : `${publicUrl}assets/img/blog/4.png`}
                                                alt={imageAlt}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevents infinite loop in case default image fails
                                                    e.target.src = `${publicUrl}assets/img/blog/4.png`; // Set default image
                                                }}
                                            />
                                        </div>
                                        <div className="details pb-4">
                                            <div className="post-meta-single mb-2">
                                                <ul>
                                                    <li>
                                                        <a className="tag-base tag-blue" href="#">
                                                            {newsData.category || 'N/A'}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <i className="fa fa-clock-o" />
                                                            {new Date(newsData.created_at).toLocaleDateString() || 'N/A'}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-user" />
                                                        {newsData.author || 'Unknown Author'}
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 className="title mt-0">{newsData.title || 'Untitled News'}</h5>
                                        </div>
                                    </div>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: newsData.content
                                                ? newsData.content.replace(/\\n|\\|\\r/g, '')
                                                : 'No content available for this news.',
                                        }}
                                    />
                                    <div className="meta">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <div className="tags">
                                                    <span>Tags: </span>
                                                    {newsData.meta_tags ? newsData.meta_tags.replace(/\\n|\\|\\\?|["<>]/g, '') : 'N/A'}
                                                </div>
                                            </div>
                                            <div className="col-lg-7 text-md-right">
                                                <div className="blog-share">
                                                    <span>Share:</span>
                                                    <ul className="social-area social-area-2 d-inline">
                                                        <li>
                                                            <a
                                                                className="facebook-icon"
                                                                href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <i className="fa fa-facebook" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="twitter-icon"
                                                                href={`https://twitter.com/share?url=${currentUrl}&text=${shareTitle}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <i className="fa fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="whatsapp-icon"
                                                                href={`https://api.whatsapp.com/send?text=${currentUrl}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <i className="fa fa-whatsapp" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="snapchat-icon"
                                                                href="https://www.snapchat.com/add/yourusername"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <i className="fa fa-snapchat" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="instagram-icon"
                                                                href="https://www.instagram.com/yourprofile"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <i className="fa fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <RelatedPost publicUrl={publicUrl} categoryId={newsData.category_id} imageFetchPath={imageFetchPath} newsId={newsData.id} />
                            </div>
                        </div>
                        <Sidebar />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewsDetails;
