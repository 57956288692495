import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import BlogDetails from './blog-components/blog-details';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const BlogDetailsPage = () => {
    const { blogId } = useParams(); // Get blog ID from URL params
    const location = useLocation(); // Access the passed state
    const [blogData, setBlogData] = useState(null); // Initialize with null
    const [error, setError] = useState(null);

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);

        const fetchBlogData = async () => {
            try {
                // Set blogData based on the location state
                if (location.state) {
                    const { blog, blogs, posts } = location.state;
                    console.log('Received state:', location.state);
                    
                    if (blog) {
                        setBlogData(blog);
                    } else if (blogs) {
                        setBlogData(blogs);
                    } else if (posts) {
                        setBlogData(posts);
                    }
                }

                // Fetch the blog data only if it was not passed via state and blogId is available
                if (!blogData && blogId) {
                    const response = await fetch(`https://muslimvoicenetwork.com/api/blog.php?blog=${blogId}`);
                    if (!response.ok) {
                        throw new Error('Error fetching blog data');
                    }
                    const data = await response.json();
                    console.log('Fetched data:', data); // Log the response data
                    setBlogData(data);
                }
            } catch (error) {
                setError(error.message);
            }
        };

        fetchBlogData();
    }, [location.state, blogId, blogData]); // Dependencies for useEffect

    if (error) {
        return <p>{error}</p>;
    }

    if (!blogData) {
        return <p>Loading...</p>; // Show loading indicator if data is not yet available
    }

    const publicUrl = process.env.PUBLIC_URL + '/';

    return (
        <div>
            <Helmet>
    <title>{blogData.title ? `${blogData.title} - Muslim's Voice Network News` : 'Blog Details - Muslim\'s Voice Network News'}</title>
    <meta name="description" content={blogData.meta_description ? blogData.meta_description : 'Discover insightful blogs and opinions from Muslim\'s Voice Network News.'} />
    <meta name="keywords" content={blogData.meta_tags ? blogData.meta_tags : 'blog details, Muslim blogs, insights, opinions, articles'} />
    <meta name="author" content="Muslim's Voice Network News" />
</Helmet>

            <Navbar />
            <PageHeader headertitle="Blog Details" />
            <BlogDetails
                blogData={blogData}
                publicUrl={publicUrl}
                postData={blogData}
            />
            <Footer />
        </div>
    );
};

export default BlogDetailsPage;
