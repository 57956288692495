import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import EventList from './event-components/event-list';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const Events = () => {
    return <div>
<Helmet>
    <title>Events - Muslim's Voice Network News | Upcoming Happenings</title>
    <meta name="description" content="Stay informed about upcoming events in your community and around the world. Muslim's Voice Network News keeps you connected to meaningful happenings." />
    <meta name="keywords" content="Muslim events, upcoming events, community gatherings, global events" />
    <meta name="author" content="Muslim's Voice Network News" />
</Helmet>


        <Navbar />
        <PageHeader headertitle="Events"  />
        <EventList />
        <Footer />
    </div>
}

export default Events

