import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import BlogList from './blog-components/blog-list';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const BlofPage = () => {
    return <div>
<Helmet>
    <title>Blogs - Muslim's Voice Network News | Insights & Opinions</title>
    <meta name="description" content="Explore thought-provoking blogs, opinions, and insights from diverse voices in the Muslim community. Stay informed and inspired with our latest articles." />
    <meta name="keywords" content="Muslim blogs, opinions, insights, articles, community opinions" />
    <meta name="author" content="Muslim's Voice Network News" />
</Helmet>


        <Navbar />
        <PageHeader headertitle="Blog"  />
        <BlogList />
        <Footer />
    </div>
}

export default BlofPage

