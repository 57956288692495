import React, { useState } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
    };

    return (
        <div>
<Helmet>
    <title>Contact Us - Muslim's Voice Network News</title>
    <meta name="description" content="Get in touch with Muslim's Voice Network News. Share your feedback, stories, or inquiries, and let us know how we can better serve you." />
    <meta name="keywords" content="Contact Muslim's Voice Network, feedback, inquiries, connect with us" />
</Helmet>


            <Navbar />
            <PageHeader headertitle="Contact Us" />
            <div className="container py-5">
                <div className="row g-4">
                    {/* Contact Details */}
                    <div className="col-lg-6">
                        <div className="shadow p-4 bg-white rounded h-100 d-flex flex-column">
                            <div className="mb-4">
                                <h1 className="mb-4 custom-heading-h1">👋 Get in Touch</h1>
                                <p>Have a question or just want to connect? We’d love to hear from you!</p>
                                <ul className="list-unstyled">
                                    <li className="mb-4"><strong>📍 Address:</strong> London, the capital of England, United Kingdom</li>
                                    <li className="mb-4"><strong>📞 Phone:</strong> +123 456 7890</li>
                                    <li className="mb-4"><strong>📧 Email 1:</strong> contact@muslimvoicenetwork.co.uk</li>
                                    <li className="mb-4"><strong>📧 Email 2:</strong> Info@muslimvoicenetwork.co.uk</li>
                                    <li className="mb-4"><strong>📧 Email 3:</strong> newsletters@muslimvoicenetwork.co.uk</li>
                                </ul>
                            </div>
                            <div className="ratio ratio-16x9 mt-auto">
                                <iframe
                                    title="England Location"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158857.83988669532!2d-0.26640339995072626!3d51.528739805038576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2s!4v1732096578724!5m2!1sen!2s"
                                    width="100%"
                                    height="100%"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>

                    {/* Contact Form */}
                    <div className="col-lg-6">
                        <div className="shadow p-4 bg-white rounded h-100">
                            <h2 className="mb-3">💌 Send Us a Message</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                        placeholder="Your Name"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                        placeholder="Your Email"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="subject" className="form-label">Subject</label>
                                    <input
                                        type="text"
                                        id="subject"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        className="form-control"
                                        placeholder="Subject"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="message" className="form-label">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows="5"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                        placeholder="Write your message here"
                                    ></textarea>
                                </div>
                                <button type="submit" className="btn btn-primary w-100">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUs;
