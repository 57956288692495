import React, { useEffect } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Helmet>
                <title>Privacy Policy - Muslim's Voice Network News</title>
                <meta name="description" content="Review the privacy policy of Muslim's Voice Network News to understand how we collect, use, and safeguard your information." />
                <meta name="keywords" content="privacy policy Muslims Voice Network, Privacy Policy Muslim's Voice Network, privacy policy, data security, information protection" />
            </Helmet>

            <Navbar />
            <PageHeader headertitle="Privacy Policy" />
            <div className="container privacy-policy-content">
                <h2>Introduction</h2>
                <p>
                    Welcome to the Privacy Policy of Muslim Voice Network. Your privacy is of utmost importance to us. This policy outlines the types of information we collect when you visit our website, subscribe to our newsletters, or contact us via our forms. It also explains how we use and protect that information.
                </p>

                <h2>Information We Collect</h2>
                <p>
                    As a platform dedicated to news, blogs, and events relevant to the Muslim community, we may collect the following types of information:
                </p>
                <ul>
                    <li>
                        <strong>Contact Information:</strong> If you use our contact form or subscribe to our newsletter, we collect your name, email address, and any additional information you provide in your message.
                    </li>
                    <li>
                        <strong>Browsing Data:</strong> We gather details such as your IP address, browser type, pages visited, and the time and date of your visits. This helps us analyze site usage and improve user experience.
                    </li>
                    <li>
                        <strong>Cookies:</strong> Cookies are used to enhance your browsing experience, manage preferences, and analyze website traffic. You can adjust cookie preferences in your browser settings.
                    </li>
                </ul>

                <h2>How We Use Your Information</h2>
                <p>
                    The information we collect is used for the following purposes:
                </p>
                <ul>
                    <li>To provide personalized content, including news, blogs, and event updates.</li>
                    <li>To send newsletters and respond to inquiries submitted through our contact forms.</li>
                    <li>To improve our website and enhance your browsing experience.</li>
                    <li>To analyze site traffic and understand user behavior.</li>
                </ul>

                <h2>Data Protection and Security</h2>
                <p>
                    We are committed to safeguarding your information. Security measures are implemented to protect your data from unauthorized access or misuse. However, please note that no method of electronic transmission or storage is completely secure.
                </p>

                <h2>Your Rights</h2>
                <p>
                    As a user of our platform, you have the following rights:
                </p>
                <ul>
                    <li>Request details about the information we collect about you.</li>
                    <li>Opt-out of receiving newsletters at any time by using the unsubscribe link in our emails.</li>
                    <li>Disable cookies via your browser settings if you prefer not to share browsing data.</li>
                </ul>

                <h2>Cookies</h2>
                <p>
                    Cookies are used to improve your experience and analyze site performance. You can accept or decline cookies through your browser settings. Declining cookies may limit certain functionalities of our website.
                </p>

                <h2>Third-Party Services</h2>
                <p>
                    We may use third-party services, such as analytics tools and newsletter platforms, to enhance our services. These providers may collect information about your interaction with our site.
                </p>

                <h2>Contact and Newsletter Forms</h2>
                <p>
                    When you use our contact form, we collect your name, email, and message details to respond to your inquiries effectively. Subscribing to our newsletter requires your email address, which we use exclusively to send relevant updates, news, and events.
                </p>
                <p>
                    We respect your preferences and ensure that any personal information provided through our forms is securely stored and not shared with third parties without your consent.
                </p>

                <h2>Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy periodically to reflect changes in our practices or services. Any updates will be posted on this page, and we encourage you to review this policy regularly.
                </p>

                <h2>Contact Us</h2>
                <p>
                    If you have any questions or concerns about our Privacy Policy or data practices, please feel free to contact us:
                </p>
                <ul>
                    <li>Email: Info@muslimvoicenetwork.co.uk</li>
                    <li>Email: contact@muslimvoicenetwork.co.uk</li>
                    <li>Email: newsletters@muslimvoicenetwork.co.uk</li>
                </ul>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
