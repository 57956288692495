import React, { useState, useEffect } from "react";

const InternetStatus = () => {
    const [isOnline, setIsOnline] = useState(true);
    const [isSlow, setIsSlow] = useState(false);

    // Check internet connection status
    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        // Optional: Check for slow internet using a timeout to fetch a small resource
        const checkConnectionSpeed = async () => {
            const start = Date.now();
            try {
                await fetch("https://www.google.com/favicon.ico", { mode: "no-cors" });
                const end = Date.now();
                if (end - start > 3000) {
                    setIsSlow(true);
                } else {
                    setIsSlow(false);
                }
            } catch {
                setIsSlow(false);
            }
        };

        const interval = setInterval(checkConnectionSpeed, 10000);
        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            {(!isOnline || isSlow) && (
                <div className={`td-search-popup ${!isOnline || isSlow ? "active" : ""}`}>
                    <div className="search-form">
                        <p style={{ textAlign: "center", margin: "20px 0" }}>
                            {!isOnline
                                ? "Internet is disconnected. Please check your connection."
                                : "Your internet is slow. Please check your network speed."}
                        </p>
                        <button
                            className="submit-btn"
                            onClick={() => {
                                setIsOnline(true);
                                setIsSlow(false);
                            }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default InternetStatus;
