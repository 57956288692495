import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import NewsDetails from './news-components/news-details';

const NewsDetailsPage = () => {
    const { newsId } = useParams();
    const location = useLocation();
    const [newsData, setNewsData] = useState(location.state ? location.state.news : null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(!newsData);

    useEffect(() => {
        window.scrollTo(0, 0);
        
        function setData(data = null) {
            if (location.state) {
                if (location.state.news) {
                    setNewsData(location.state.news);
                } else if (location.state.recentPosts) {
                    setNewsData(location.state.recentPosts);
                } else if (location.state.popularPosts) {
                    setNewsData(location.state.popularPosts);
                } else if (location.state.trendingPosts) {
                    setNewsData(location.state.trendingPosts);
                } else if (location.state.latestNews) {
                    setNewsData(location.state.latestNews);
                } else if (location.state.TrendingNews) {
                    setNewsData(location.state.TrendingNews);
                } else if (location.state.WhatsNews) {
                    setNewsData(location.state.WhatsNews);
                }
                else {
                    setNewsData(data);
                }
            }
        }
        setData(null);

        const fetchData = () => {
            if (!newsData && newsId) {
                fetch(`https://muslimvoicenetwork.com/api/blog.php?news=${newsId}`)
                    .then((response) => {
                        if (!response.ok) throw new Error('Error fetching news data');
                        return response.json();
                    })
                    .then((data) => {
                        setNewsData(data[0]); // Assuming the API returns an array
                        setData(data);
                        setLoading(false);
                    })
                    .catch((error) => {
                        setError(error.message);
                        setLoading(false);
                    });
            } else {
                setLoading(false);
            }
        };

        fetchData();
    }, [location.state, newsId, newsData]);

    if (error) {
        return <p>{error}</p>;
    }

    const publicUrl = process.env.PUBLIC_URL + '/';

    return (
        <div>
            <Navbar />
            <PageHeader headertitle="News Details" />
            <NewsDetails newsData={newsData} publicUrl={publicUrl} loading={loading} />
            <Footer />
        </div>
    );
};

export default NewsDetailsPage;
