import React , { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Cat from './section-components/cat-sports';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom'; // To get URL parameters
import { Helmet } from 'react-helmet'; // For managing meta tags

const CatNews = () => {
    const { catId } = useParams(); // Extract `catId` from the URL
    const [categoryData, setCategoryData] = useState({
        title: 'Category - Muslim Voice Network', // Fallback title
        meta_description: 'Explore the latest news and updates in this category on Muslim Voice Network.', // Fallback description
        meta_tags: 'Muslim Voice, News, Categories, Latest Updates, Trending News', // Fallback keywords
    });

    // Fetch category details using `catId`
    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const response = await fetch(`https://muslimvoicenetwork.com/api/blog.php?category=news_categories&cat_id=${catId}`);
                const data = await response.json();

                if (data && data.length > 0) {
                    const category = data[0]; // Access the first object in the array
                    setCategoryData({
                        title: category.category+' News & Updates' || 'News & Updates Category',
                        meta_description: category.meta_description || 'Explore the latest news and updates in this category on Muslim Voice Network.',
                        meta_tags: category.meta_tags || 'Muslim Voice, News, Categories, Latest Updates, Trending News',
                    });

                    console.log('Title is ' + category.category);
                } else {
                    console.warn('No data found for the given category ID.');
                }
            } catch (error) {
                console.error('Error fetching category details:', error);
            }
        };

        if (catId) {
            fetchCategory();
        }
    }, [catId]);
    
    return <div>
        <Helmet>
                <title>{categoryData.title+' - Muslim Voice Network'}</title>
                <meta name="description" content={categoryData.meta_description} />
                <meta name="keywords" content={categoryData.meta_tags} />
            </Helmet>

            <Navbar />
            <PageHeader headertitle={categoryData.title || 'Muslim News'} />

        <Cat />
        <Footer />
    </div>
}

export default CatNews

