import React, { useState } from 'react';
import Navbar from './global-components/navbar-v2';
import BannerV2 from './section-components/banner-v2';
import Trending from './section-components/trending-v3';
import Catv2 from './section-components/cat-section-v2';
import NewsLatter from './section-components/newsletter';
import Footer from './global-components/footer';
import Loader from './global-components/loader';
import { Helmet } from 'react-helmet';

const Home_V2 = () => {
  const [loading, setLoading] = useState(true);
  return (
    <div>
      <Helmet>
        <title>Home - Muslim's Voice Network News | Amplifying Muslim Voices</title>
        {/* Meta Description */}
        <meta 
          name="description" 
          content="Welcome to Muslim's Voice Network News. Stay updated with the latest stories, opinions, and analyses tailored for the Muslim community worldwide" 
        />
        
        {/* Meta Keywords */}
        <meta 
          name="keywords" 
          content="Muslim news, Islamic community updates, global Muslim stories, opinions, Islamic perspectives, latest news, muslim, muslim voice news" 
        />
        
        

        {/* Open Graph Tags for Social Media */}
        <meta property="og:title" content="Home - Amplifying Muslim Voices | Muslim's Voice Network News" />
        <meta property="og:description" content="Welcome to Muslim's Voice Network News. Stay updated with the latest stories, opinions, and analyses tailored for the Muslim community worldwide." />

        {/* X-Robots-Tag (through HTTP headers via server configuration) */}
        {/* Note: This should be set at the server level. */}
        {/* Example: X-Robots-Tag: "index, follow" */}

        {/* Viewport */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>


      <Navbar />
      {/* {loading && <div style={{display: "flex", justifyContent: "center", marginTop: 10, gap: 20}}><Loader /> <Loader /> <Loader /></div>} Show loader when loading is true */}
      <BannerV2 setParentLoading={setLoading} />
      <Trending setParentLoading={setLoading} />
      <Catv2 />
      <NewsLatter />
      <Footer />
    </div>
  );
};

export default Home_V2;
